import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Routes, Link, useParams } from "react-router-dom";
import logo from './assets/tactile_head_logo.png';
import yellowSquare from './assets/yellow_square.png';
import redSquare from './assets/red_square.png';
import footerLogo from './assets/tactile_foot_logo.png';
import homeLink from "./assets/home.png";
import './App.css';

import projectData from './projects.json';
import clientData from "./clients.json";

function ProjectCard(props)
{
	const { project } = props;
	return (
		<li className="project">
			<h3>
			    { project.details ?  <Link to={"/project/"+project.key}>{project.name}</Link>
			        : <a href={project.url} target="_blank" rel="noreferrer noopener">{project.name}</a>
			    }
			</h3>
			{
			    project.embed ? <div className="videocontainer"><iframe src={project.embed} title={project.key} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe></div>
			        : <Link to={"/project/"+project.key}><img src={ "/thumbnails/" + project.thumbnail } className="thumbnail" alt="" /></Link>
			}
			<div className="info">{project.description}</div>
		</li>
	);
}

function Home()
{
	return (
		<div className="home">
			<div className="company-info yellow">
			  <div className="about"><span className="stronger">Tactile Pictures</span> is an integrated design, production and engineering 
			  studio, founded in San Francisco in 1995.
			  We provide <span className="stronger">digital motion content, graphic and interface design</span>, and <span className="stronger">web and 
			  software development services</span> to clients worldwide, including startups and established companies, foundations, and nonprofit organizations.</div>
			  <div className="about">Our mission is to enable our clients to communicate effectively through the innovative use of 
			  media and technology, and to develop new technology to improve lives and communities. We take pride in listening to our 
			  clients and their customers, and providing creative solutions within budget and on schedule.</div>

			  <img src={redSquare} className="upperleft-corner" alt="" />
			  <img src={redSquare} className="lowerleft-corner" alt="" />
			  <img src={redSquare} className="upperright-corner" alt="" />
			  <img src={redSquare} className="lowerright-corner" alt="" />
			</div>
			<div className="company-portfolio hide-scroll">
			  <h3>Selected Projects</h3>
			  <ul className="scroller">
			  {
				  projectData.map((project, index) =>
					<ProjectCard key={project.key} project={project} />
					)
			  }
			  </ul>
			  <div className="client-info"><Link to="/clients/">full client list &gt;</Link></div>
			  <div className="yellow-strip" />
			</div>
			<div className="company-info yellow">
			  <h3>Director</h3>
			  <div className="bio"><span className="stronger">Nisma Zaman</span> is an award-winning multifaceted director and producer of digital multimedia content, a product strategy consultant, and a hands-on product manager and UI/UX designer on apps and platforms. She can move seamlessly from a big-picture view to a focus on minute details to ensure high quality deliverables on time and on budget. Most recently, she led development, ideation, design, and engineering on web apps for One Nation/One Project’s <a href="https://www.artsforeverybody.org" target="_blank" rel="noreferrer noopener">Arts for Everybody</a> campaign, that converted two research surveys into joyful “artified” (a term she coined) experiences. Nisma also served as a judge for <a href="https://foundation.mozilla.org/en/what-we-fund/awards/creative-media-awards/" target="_blank" rel="noreferrer noopener">Mozilla’s Creative Media Awards</a> and as a mentor. She is working behind the screens to advocate for responsible technologies and advises the leadership of several digital apps.</div>
              <div className="bio">Previously in NYC, Nisma taught four semesters of an Emerging Media Foundation college course, created digital content for ArtBridge, UOVO, the Jewish Museum, and Mana Contemporary. Over a five-year full-time staff job, Nisma produced a wide variety of digital content for a global contemporary art initiative at the Guggenheim Museum (<a href="https://guggenheim.org/map" target="_blank" rel="noreferrer noopener">guggenheim.org/map</a>), including over 160 videos filmed in NYC, Singapore, Hong Kong, London, and Mexico City. Her background is in film and television and she has a Daytime Emmy Award for her Coordinating Producer role on a 52-episode series for Nick Jr. Visit <a href="https://nismazaman.com" target="_blank" rel="noreferrer noopener">https://nismazaman.com</a> for more info. </div>
			  <h3>Founder</h3>
			  <div className="bio"><span className="stronger">Derek Chung</span> brings both technical and creative backgrounds to Tactile Pictures. With Tactile Pictures, he created the <span className="stronger">Tactile12000</span> and <span className="stronger">Tactile1800</span> DJ mixing apps, featured in <em>Print Magazine</em>'s Interaction 2000 as well as music and software magazines around the world.</div>
			  <div className="bio">He was previously a computer scientist at <span className="stronger">Adobe Systems</span> and Managing Director of <span className="stronger">Kearny Street Workshop</span>. His artwork has been exhibited at Shadowshop at SFMOMA, Matcha at the Asian Art Museum, Kearny Street Workshop and other galleries and online venues.</div>
			  <img src={redSquare} className="upperleft-corner" alt="" />
			  <img src={redSquare} className="lowerleft-corner" alt="" />
			  <img src={redSquare} className="upperright-corner" alt="" />
			  <img src={redSquare} className="lowerright-corner" alt="" />
			</div>
        </div>
	)
}

class Clients extends Component 
{
  componentDidMount() {
      window.scrollTo(0, 0);
  }

  render() {
	return (
		<div className="client-container yellow">
		    <p>Here are some of the clients we have worked with over the past twenty-five years:</p>
			<ul>
			  {
				  clientData.map((client, index) =>
				  	 client.hidden !== true && <li key={index}>{client.name}</li>
					)
			  }
			</ul>
		</div>
	)
	}
}

function Project()
{
    const { key } = useParams();
	var project = null;
	var previous = null;
	var next = null;
	for (var i=0; i < projectData.length; ++i)
	{
		if (projectData[i].key === key)
		{
			project = projectData[i];
			if (i > 0) {
				previous = projectData[i-1].key;
			}
			if (i+1 < projectData.length) {
				next = projectData[i+1].key;
			}
			break;
		}
	}
	return ( project != null &&
    <div className="project-container yellow">
	  <h3>{project.name}</h3>
	  { 
	    project.thumbnail && <div><img src={ "/thumbnails/" + project.thumbnail } className="thumbnail" alt={project.name} /></div> 
	  }
	  { 
	    project.embed && <div className="videocontainer"><iframe src={project.embed} title={project.key} frameBorder="0" allow="autoplay; fullscreen" allowFullScreen></iframe></div> 
	  }
      
	  <div className="project-details">{project.details}</div>
	  { project.url && <a href={project.url}>{project.url}</a> }
	  { previous && <div className="previous-project"><Link to={"/project/"+previous}>&lt;</Link></div> }
	  { next && <div className="next-project"><Link to={"/project/"+next}>&gt;</Link></div> }
	  <div className="home-link"><Link to={"/"}><img src={homeLink} alt="home"/></Link></div>
    </div>
  );
}

class App extends Component {

  constructor(props) {
    super(props);
    
    this.state = {
       data: projectData
    };
  }

  render() {
    return (
      <div className="App">
        <Router>
			<header className="App-header">
			  <Link to="/"><img src={logo} className="App-logo" alt="Tactile Pictures" /></Link>
			  <img src={yellowSquare} className="upperleft-corner" alt="" />
			  <img src={yellowSquare} className="lowerleft-corner" alt="" />
			  <img src={yellowSquare} className="upperright-corner" alt="" />
			  <img src={yellowSquare} className="lowerright-corner" alt="" />
			</header>
			<Routes>
                <Route path="/" exact element={<Home />} />
                <Route path="/clients/" element={<Clients />} />
                <Route path="/project/:key" element={<Project />} />
            </Routes>
			<div className="footer red">
			  <Link to="/"><img src={footerLogo} className="logo" alt="Tactile Pictures, San Francisco, CA" /></Link>
			  <img src={yellowSquare} className="upperleft-corner" alt="" />
			  <img src={yellowSquare} className="lowerleft-corner" alt="" />
			  <img src={yellowSquare} className="upperright-corner" alt="" />
			  <img src={yellowSquare} className="lowerright-corner" alt="" />
			</div>
		</Router>
      </div>
    );
  }
}

export default App;
